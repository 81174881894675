import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function CrescentMoon() {
  return (
    <Layout>
      <Helmet title="Crescent Moon | Conor Albert + James Smith" />

      <h1>Crescent Moon</h1>

      <h2>Conor Albert + James Smith</h2>

      <h4>Key: F#</h4>

      <h4>Tempo: 68</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>C</Ch>They say love hurts <Ch>G</Ch>I wrote that book
          </p>
          <p>
            <Ch>Am</Ch>I climbed that wall, <Ch>F</Ch>I had one look
          </p>
          <p>
            <Ch>C</Ch>But you just came a<Ch>G</Ch>round, to say <Ch>Am</Ch>
            hello <Ch>F</Ch>
          </p>
        </Verse>

        <Verse>
          <p>
            <Ch>F#</Ch>Lonely, you were my only
          </p>
          <p>It's like you were a thousand miles away</p>
          <p>
            From ever falling in<Ch>D#m9</Ch>side my arms (aah, aah)
          </p>
        </Verse>
        <Verse>
          <p>
            Baby, I caught <Ch>G#m7</Ch>you, dived into the water
          </p>
          <p>Held you so tight like finding riches as a pauper</p>
          <p>
            I <Ch>C#9</Ch>stole a heart
          </p>
        </Verse>

        <Chorus>
          <p>
            From someone like<Ch>G#m7</Ch> you
          </p>
          <p>
            But I never <Ch>C#9</Ch>knew
          </p>
          <p>
            When I threw a <Ch>F#</Ch>line
          </p>
          <p>
            From <Ch>F#</Ch>my <Ch>F7</Ch>cres<Ch>E7</Ch>cent <Ch>D#7</Ch>moon
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you
          </p>
        </Chorus>

        <Verse>
          <p>
            I'm counting my <Ch>F#</Ch>blessings
          </p>
          <p>You brought me heaven</p>
          <p>Sittin' in the sky, looking back at adolescence</p>
          <p>
            With<Ch>D#m9</Ch>out you by my side
          </p>
        </Verse>

        <Verse>
          <p>
            Oh, I'm unworthy of a <Ch>G#m7</Ch>woman like yourself
          </p>
          <p>I couldn't ever imagine something so perfect</p>
          <p>
            Oh, how in the hell did I <Ch>C#9</Ch>find?
          </p>
        </Verse>

        <Chorus>
          <p>
            Someone like<Ch>G#m7</Ch> you
          </p>
          <p>
            But I never <Ch>C#9</Ch>knew
          </p>
          <p>
            When I threw a <Ch>F#</Ch>line
          </p>
          <p>
            From <Ch>F#</Ch>my <Ch>F7</Ch>cres<Ch>E7</Ch>cent <Ch>D#7</Ch>moon
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you<Ch>D#9</Ch>
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you
          </p>
        </Chorus>

        <Bridge>
          <p>
            <b>F#</b> <b>D#m9</b> <b>G#m7</b> <b>C#9</b>
          </p>
        </Bridge>

        <Chorus>
          <p>
            I found someone like<Ch>G#m7</Ch> you
          </p>
          <p>
            But I never <Ch>C#9</Ch>knew
          </p>
          <p>
            When I threw a <Ch>F#</Ch>line
          </p>
          <p>
            From <Ch>F#</Ch>my <Ch>F7</Ch>cres<Ch>E7</Ch>cent <Ch>D#7</Ch>moon
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you<Ch>D#9</Ch>
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you
          </p>
          <p>
            And out of the <Ch>G#m</Ch>blue
          </p>
          <p>
            Was something un<Ch>Bm7</Ch>true
          </p>
          <p>
            Someone like <Ch>F#</Ch>you
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
